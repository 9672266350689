const marginDesktop = {
  pageContentTop: '1.125rem',
  topBackButtonTop: '-0.375rem',
  topBackButtonBottom: '.125rem',
  wrapperDividerTop: '.625rem',
  wrapperDividerBottom: '1rem',
  sidebarBrandIconRight: '1rem',
  wrapperTableToHeader: '2rem',
  wrapperTableToHeaderInGrid: '.5rem',
  headerTopInGrid: '.5rem',
  largeHeaderTopInGrid: '1rem',
  headerTop: '2rem',
  submitButtonTopInGrid: '1.5rem',
  paginationTop: '1.5rem',
  paginationTopInGrid: '.5rem',
  twoTypographyHorizontal: '.5rem',
  twoTypographyVertical: '.5rem',
  headerToContent: '2rem',
  headerToContentInGrid: '.5rem',
  privateAndSyncCheckboxesLeft: '.438rem'
};

export default marginDesktop;
