import { lazy } from 'react';

const routes = [
  {
    path: '/dashboard',
    component: lazy(() => import('./Dashboard')),
    anonymousAccess: true,
    routes: [
      {
        path: 'errors/error-401',
        component: lazy(() => import('./pages/desktop/errors/Error401')),
        anonymousAccess: true
      },
      {
        path: 'user-profile',
        component: lazy(() =>
          import('./pages/desktop/user-profile/UserProfile')
        ),
        mobile: lazy(() => import('./pages/mobile/user-profile/UserProfile')),
        anonymousAccess: true
      },
      {
        path: 'employees-list',
        component: lazy(() =>
          import('./pages/desktop/employees-list/EmployeesList')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/employees-list/EmployeesList')
        ),
        authKeys: ['EmployeeList']
      },
      {
        path: 'employee-registration',
        component: lazy(() =>
          import('./pages/desktop/employee-registration/EmployeeRegistration')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/employee-registration/EmployeeRegistration')
        ),
        anonymousAccess: true,
        authKeys: ['AddEmployee']
      },
      {
        path: 'change-pass-user',
        component: lazy(() =>
          import('./pages/desktop/change-pass-user/ChangePassUser')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/change-pass-user/ChangePassUser')
        ),
        anonymousAccess: true
      },
      {
        path: 'employee-profile',
        component: lazy(() =>
          import('./pages/desktop/employee-profile/EmployeeProfile')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/employee-profile/EmployeeProfile')
        ),
        anonymousAccess: true
      },
      {
        path: 'update-permissions-user',
        component: lazy(() =>
          import('./pages/desktop/update-permission-user/UpdatePermissionsUser')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/update-permission-user/UpdatePermissionsUser')
        ),
        anonymousAccess: true
      },
      {
        path: 'system-version',
        component: lazy(() =>
          import('./pages/desktop/system-version/VersionList')
        ),
        mobile: lazy(() => import('./pages/mobile/system-version/VersionList')),
        anonymousAccess: true
      },
      {
        path: 'purchase-type',
        component: lazy(() =>
          import('./pages/desktop/purchase-type/PurchaseType')
        ),
        mobile: lazy(() => import('./pages/mobile/purchase-type/PurchaseType')),
        anonymousAccess: true
      },
      {
        path: 'purchase-method',
        component: lazy(() =>
          import('./pages/desktop/purchase-step/PurchaseStep')
        ),
        mobile: lazy(() => import('./pages/mobile/purchase-step/PurchaseStep')),
        anonymousAccess: true
      }
    ]
  },
  {
    path: '/*',
    component: lazy(() => import('./pages/desktop/errors/Error404'))
  }
];

export default routes;
