import { componentSizesExtraVars } from 'spec';

const componentSizesTablet = {
  navbar: {
    height: '62px'
  },
  sidebar: {
    opened: {
      width: '228px'
    },
    closed: {
      width: '70px'
    }
  },
  fileUploader: '297px',
  adornmentIcon: '20px',
  inputBoxHeight: '36px',
  filterFieldHeight: '40px',
  showMoreRowsOfTableButtonHeight: '32px',
  ...componentSizesExtraVars.common,
  ...componentSizesExtraVars.tablet
};

export default componentSizesTablet;
