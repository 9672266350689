import { componentSizesExtraVars } from 'spec';

const componentSizesLaptop = {
  navbar: {
    height: '3.875rem'
  },
  sidebar: {
    opened: {
      width: '16rem'
    },
    closed: {
      width: '4.375rem'
    }
  },
  fileUploader: '18.563rem',
  adornmentIcon: '1.25rem',
  inputBoxHeight: '2.25rem',
  filterFieldHeight: '2.5rem',
  showMoreRowsOfTableButtonHeight: '2rem',
  ...componentSizesExtraVars.common,
  ...componentSizesExtraVars.laptop
};

export default componentSizesLaptop;
