const marginLaptop = {
  pageContentTop: '.75rem',
  topBackButtonTop: '-0.375rem',
  topBackButtonBottom: '.125rem',
  wrapperDividerTop: '.625rem',
  wrapperDividerBottom: '1rem',
  sidebarBrandIconRight: '1rem',
  wrapperTableToHeader: '.875rem',
  wrapperTableToHeaderInGrid: '0',
  headerTopInGrid: '.5rem',
  largeHeaderTopInGrid: '1rem',
  headerTop: '2rem',
  submitButtonTopInGrid: '1.5rem',
  paginationTop: '.75rem',
  paginationTopInGrid: '0',
  twoTypographyHorizontal: '.375rem',
  twoTypographyVertical: '.5rem',
  headerToContent: '2rem',
  headerToContentInGrid: '.5rem',
  privateAndSyncCheckboxesLeft: '.438rem'
};

export default marginLaptop;
